<template>
  <div>
    <b-card title="Güncelle">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Araca Takılacak Lastik"
            label-for="customer_tires"
          >
            <v-select
              id="customer_tires"
              v-model="tires.id_com_tire_size"
              label="tire_size"
              :options="tires"
              placeholder="Araca Takılacak Lastik"
              :reduce="tires => tires.id"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Raf"
            label-for="id_com_tire_shelf"
          >
            <v-select
              id="id_com_tire_shelf"
              v-model="tires.id_com_tire_shelf"
              label="title"
              :options="tireShelfs"
              placeholder="Raf"
              :reduce="tireShelfs => tireShelfs.id"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Teknisyen"
            label-for="id_com_user"
          >
            <v-select
              id="id_com_user"
              v-model="tires.id_com_user"
              label="name"
              :options="users"
              placeholder="Teknisyen"
              :reduce="users => users.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Lastik Ekle">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Lastik Ebatı"
            label-for="id_com_tire_size"
          >
            <v-select
              id="id_com_tire_size"
              v-model="tires.id_com_tire_size"
              label="title"
              :options="tireSizes"
              placeholder="Lastik Ebatı"
              :reduce="tireSizes => tireSizes.id"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Lastik Markası"
            label-for="id_com_tire_brand"
          >
            <v-select
              id="id_com_tire_brand"
              v-model="tires.id_com_tire_brand"
              label="title"
              :options="tireBrands"
              placeholder="Lastik Markası"
              :reduce="tireBrands => tireBrands.id"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Lastik Tipi"
            label-for="id_com_tire_type"
          >
            <v-select
              id="id_com_tire_type"
              v-model="tires.id_com_tire_type"
              label="title"
              :options="tireTypes"
              placeholder="Lastik Tipi"
              :reduce="tireTypes => tireTypes.id"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Raf"
            label-for="id_com_tire_shelf"
          >
            <v-select
              id="id_com_tire_type"
              v-model="tires.id_com_tire_shelf"
              label="title"
              :options="tireShelfs"
              placeholder="Raf"
              :reduce="tireShelfs => tireShelfs.id"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Teknisyen"
            label-for="id_com_user"
          >
            <v-select
              id="id_com_user"
              v-model="tires.id_com_user"
              label="name"
              :options="users"
              placeholder="Teknisyen"
              :reduce="users => users.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      @click="saveData"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCard,
    vSelect,
  },
  computed: {
    dataItem() {
      return this.$store.getters['tireHotel/dataItem']
    },
    tires() {
      return this.$store.getters['tireHotel/dataList']
    },
    tireSizes() {
      return this.$store.getters['tireSizes/getTireSizes']
    },
    tireBrands() {
      return this.$store.getters['tireBrands/getTireBrands']
    },
    tireTypes() {
      return this.$store.getters['tireTypes/getTireTypes']
    },
    tireShelfs() {
      return this.$store.getters['tireShelfs/getTireShelfs']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    saveStatus() {
      return this.$store.getters['tireHotel/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getDataItem()
    this.getTireSizes()
    this.getTireBrands()
    this.getTireTypes()
    this.getTireShelfs()
    this.getUsers()
  },
  methods: {
    getDataItem() {
      this.$store.dispatch('tireHotel/getDataItem', this.$route.params.id)
        .then(res => {
          if (res.id_com_customer) {
            this.getTires(res.id_com_customer)
          }
        })
    },
    getTires(id) {
      this.$store.dispatch('tireHotel/getDataList', {
        where: {
          'com_tire_hotel.id_com_customer': id,
        },
      })
    },
    getTireSizes() {
      this.$store.dispatch('tireSizes/tireSizesList')
    },
    getTireBrands() {
      this.$store.dispatch('tireBrands/tireBrandsList')
    },
    getTireTypes() {
      this.$store.dispatch('tireTypes/tireTypesList')
    },
    getTireShelfs() {
      this.$store.dispatch('tireShelfs/tireShelfsList')
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        'com_user.status': 1,
      })
    },
    saveData() {
      this.$store.dispatch('tireHotel/saveData', this.dataItem)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
